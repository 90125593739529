@import "variables";
@import "bootstrap";

svg {
  &.spotify {
    color: #18d960;
  }

  &.soundcloud {
    color: #f9670d;
  }
}